














































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { BannerCarousel, ImageBox } from '~/components/widgets';
import {
  BusinessServices,
  ContactUsBusiness,
  FooterBlurb,
  ImageBlurb
} from '~/components/content';
import BusinessContactForm from '~/components/forms/BusinessContactForm.vue';
import BusinessContactSideForm from '~/components/forms/BusinessContactSideForm.vue';
import buildMeta from '~/libraries/meta';

@Component({
  components: {
    ImageBox,
    BannerCarousel,
    BusinessContactForm,
    BusinessContactSideForm,
    BusinessServices,
    ContactUsBusiness,
    FooterBlurb,
    ImageBlurb
  }
})
export default class HomePage extends Vue {
  product: String = '';
  location: String = '';
  latitude: Number = 0;
  longitude: Number = 0;
  geolocationSet: Boolean = false;
  errorStr: String = '';
  mounted() {
    if (localStorage.getItem('geolocation')) {
      let geolocation = JSON.parse(String(localStorage.getItem('geolocation')));
      this.latitude = geolocation.latitude;
      this.longitude = geolocation.longitude;
      this.setLocation(this.latitude, this.longitude);
    } else {
      this.getGeolocation();
    }
    let hash = this.$route.hash.replace('#', '.');
    const that = this;
    if (hash) {
      if (hash.indexOf('?')) {
        hash = hash.split('?')[0];
      }
      const els = document.querySelectorAll(hash);
      els.forEach(function(el) {
        const style = getComputedStyle(el);
        if (style.display !== 'none' && parseInt(style.width) <= 1023) {
          that.$scrollTo(el, { offset: 0 });
        } else if (style.display !== 'none' && parseInt(style.width) > 1023) {
          that.$scrollTo(el, { offset: -70 });
        }
      });
    }
  }
  head() {
    return buildMeta({
      title: this.$t('bw.index:title'),
      description: this.$t('bw.index:meta-description'),
      canonical: 'https://www.beanfield.com/business',
      socialImage: 'business-homepage',
      imageType: 'image/jpeg'
    });
  }
  layout() {
    return 'business';
  }
  setProduct(product) {
    this.product = product;
    this['$ga'].event({
      eventCategory: 'Get in Touch',
      eventAction: this.product,
      eventLabel: this['$route'].path
    });
  }
  getGeolocation() {
    if (!this.geolocationSet) {
      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
      }
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.latitude = pos.coords.latitude;
          this.longitude = pos.coords.longitude;
          localStorage.setItem(
            'geolocation',
            JSON.stringify({
              latitude: this.latitude,
              longitude: this.longitude
            })
          );
          this.geolocationSet = true;
          this.setLocation(this.latitude, this.longitude);
        },
        err => {
          this.errorStr = err.message;
        }
      );
    } else {
      let geolocation = JSON.parse(String(localStorage.getItem('geolocation')));
      this.latitude = geolocation.latitude;
      this.longitude = geolocation.longitude;
      this.setLocation(this.latitude, this.longitude);
    }
  }
  setLocation(lat, lng) {
    if (lat >= 43.68 && lat <= 52.48 && lng >= -79.38 && lng <= -71.55) {
      this.location = 'Montreal';
    } else if (lat >= 43.45 && lat <= 50 && lng >= -86 && lng <= -76.5) {
      this.location = 'Toronto';
    } else if (lat >= 48.3 && lat <= 60 && lng >= -139.06 && lng <= -114.03) {
      this.location = 'Vancouver';
    } else {
      this.location = '';
    }
  }
}
